import React from "react";
import { useState } from "react";
import {useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { FileUploader, Button } from "@ui5/webcomponents-react";
import { ThemeProvider,Loader } from '@ui5/webcomponents-react';
import { ComboBox,ComboBoxItem,Icon } from '@ui5/webcomponents-react';
import { useEffect } from "react";
import { DatePicker } from '@ui5/webcomponents-react';
import { CheckBox } from '@ui5/webcomponents-react';
import "./comp.css";
import Alert from "./Alert";
const Updatepi = () => {
    const {id} = useParams();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [currentposition, setCurrentposition] = useState("");
  const [cvlink, setCvlink] = useState("");
  const [prevfilevalue, setPrevfilevalue] = useState("");
  const [joiningdate,setJoiningdate] = useState("")
  const [filevalue, setFilevalue] = useState("");
  const [membertype,setMembertype] = useState("");
  const [loading,setLoading] = useState(false);
  const [alert,setAlert] = useState(null);
  const [expertise, setExpertise] = useState("");
  const [prevfilelink,setPrevfilelink] = useState("");
  const [imageupload,setImageupload] = useState(true);
  const navigate = useNavigate();
  const showAlert = (message,type)=>{
    setAlert({
       mes:message,
       type:type,
    })
   setTimeout(() => {
           setAlert(null);
          //  window.location.reload();
   }, 1500);
}
useEffect(()=>{
    const fetchData = async () => {
       await axios.get(`${process.env.REACT_APP_BACKEND_URL}/member/${id}`)
        .then((res) => {
            setName(res.data.name);
            setEmail(res.data.email);
            setCurrentposition(res.data.currentposition);
            setCvlink(res.data.cvlink);
            setMembertype(res.data.membertype);
            setExpertise(res.data.expertise);
            setPrevfilevalue(res.data.coverimgid);
            setPrevfilelink(res.data.coverimg)
            setJoiningdate(new Date(res.data.joiningdate).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' }));
            console.log(res);
        })
        .catch((err) => {
            console.log(err);
        });
    }
    fetchData();
},[])
  const sendpost = async (e) => {
    e.preventDefault();
    console.log(email)
    console.log(currentposition)
    console.log(cvlink)
    console.log(filevalue)
    console.log(membertype)
    console.log(expertise)
    console.log(prevfilevalue)
    if((!name) || (!email) || (!currentposition) || (!cvlink) || (!filevalue && imageupload) || (!membertype) || (!expertise) || (!joiningdate))
    {
      showAlert("Please fill all the fields","danger");
      return;
    }
    if (!email.includes("@")) {
      showAlert("Invalid email", "danger");
      return;
    }
    setLoading(true)
    const formdataimg = new FormData();
    const formdata = new FormData();
    if(imageupload)
    {
        formdataimg.append("file", filevalue[0]);
        formdataimg.append("upload_preset", "cdriimg");
        formdataimg.append("cloud_name", "drujdpuxv");
        try {
        const response = await axios.post(
            "https://api.cloudinary.com/v1_1/drujdpuxv/image/upload/",
            formdataimg
        );
        
        // Access the response data directly
        formdata.append("imglink",response.data.url);
        formdata.append("imgid",response.data.public_id);
        } catch (err) {
        console.error("Error uploading image:", err);
        }
    }
    else
    {
        formdata.append("imglink",prevfilelink);
        formdata.append("imgid",prevfilevalue);
    }
    
    
    formdata.append("name", name);
    formdata.append("email", email);
    formdata.append("currentposition", currentposition);
    formdata.append("cvlink", cvlink);
    formdata.append("membertype", membertype);
    formdata.append("expertise", expertise);
    formdata.append("previmgid", prevfilevalue);
    formdata.append("joiningdate",joiningdate);
    // console.log(formdata)
    await axios.put(`${process.env.REACT_APP_BACKEND_URL}/updatemember/${id}`, formdata)
      .then((res) => {
        setLoading(false)
        navigate("/pipage");
      })
      .catch((err) => {console.log(err);
        showAlert(`${err.message}`,"danger");});
  };

  return (
    <ThemeProvider >
  {loading ?(
    <div style={{
      height:"100%",
      width:"100%"
    }}>
      <Loader progress="60%" />
    </div>
  ) : (
    <div className="adddmember-content">
      <Alert alert={alert}/>
    <form action="post">
      <input
        type="name"
        placeholder="Name"
        value={name}
        required
        onChange={(e) => setName(e.target.value)}
      />
      <input
        type="email"
        placeholder="Email"
        value={email}
        required
        onChange={(e) => setEmail(e.target.value)}
      />
      <input
        type="text"
        placeholder="Current Position"
        value={currentposition}
        required
        onChange={(e) => setCurrentposition(e.target.value)}
      />
      <input
        type="text"
        placeholder="Expertise"
        value={expertise}
        required
        onChange={(e) => setExpertise(e.target.value)}
      />
      <DatePicker
        onChange={(e) => setJoiningdate(e.target.value)}
        onInput={function _a(){}}
        primaryCalendarType="Gregorian"
        value={joiningdate}
        placeholder="Ex Sep 14, 2015"
        required
      />
      <input
        type="text"
        placeholder="Drive link for Cv"
        value={cvlink}
        required
        onChange={(e) => setCvlink(e.target.value)}
      />
      
      <CheckBox
        onChange={(e) => setImageupload(!imageupload)}
        text="Click if you don't want to change image"
        valueState="None"
        />
      {imageupload&&(
        <FileUploader onChange={(e) => setFilevalue(e.target.files)} accept="image/*" required>
        <Button
        style={{
          color: "blue",
        }}
        >Your Profile Image</Button>
      </FileUploader>
      )}
      <ComboBox
      icon={<Icon name="employee" />}
      onChange={e=>setMembertype(e.target.value)}
      onInput={function _a(){}}
      onSelectionChange={function _a(){}}
      value={membertype}
    >
      <ComboBoxItem text="Principal Investigator" />
      <ComboBoxItem text="Current Members" />
      <ComboBoxItem text="Alumni" />
    </ComboBox>
      <Button onClick={sendpost}
        style={{
          color: "blue",
        }}
        >Update Member</Button>
    </form>
  </div>
  )}
  </ThemeProvider>
);
};

export default Updatepi;
