import React, { useEffect, useState } from 'react';
import { Outlet, Navigate } from 'react-router-dom';


const Privateroutes = () => {
  const [authenticated, setAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const retrievedData = localStorage.getItem("jwt token");
    if (retrievedData) {
      fetch(`${process.env.REACT_APP_BACKEND_URL}/protected-route`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${retrievedData}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          
          data.status === "ok"
            ? setAuthenticated(true)
            : setAuthenticated(false);
          setLoading(false); // Set loading to false once the authentication check is complete
        })
        .catch((error) => {
          // return res.status(401).json({ status: 'error', error: 'Unauthorized - Token has expired' });
          if(error === 'Unauthorized - Token has expired')
          {
            localStorage.removeItem("jwt token");
            window.location.reload();
          }
          console.error("Error:", error);
          setLoading(false); // Set loading to false in case of an error
        });
    } else {
      setLoading(false); // If no token is present, set loading to false
    }
  }, []);
  if (loading) {
    return <p>Loading...</p>;
  }

  return authenticated ? <Outlet /> : <Navigate to="/login" />;
}

export default Privateroutes;


