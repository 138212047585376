
import React from "react";
import { useState, useEffect } from "react";
import {Button } from "@ui5/webcomponents-react";
import { Link } from "react-router-dom";
import authenticate from "../components/Authfunction";
import { IllustratedMessage } from '@ui5/webcomponents-react';
import "@ui5/webcomponents-icons/dist/add-folder.js";
import Carousel from '../container/Carousel.js'
import { ThemeProvider,Loader } from '@ui5/webcomponents-react';
const Gallery = () => {
  const [authenticated, setAuthenticated] = useState(false);
  const [images, setImages] = useState([]);
  const [emptyarray,setEmptyarray] = useState(false);
  const [galleryData, setGalleryData] = useState({});
  const [loading,setLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response =await  fetch(`${process.env.REACT_APP_BACKEND_URL}/addgallery`);
        const image = await response.json();
        if (Array.isArray(image) && image.length === 0) {
          console.log("The response is empty");
          setEmptyarray(true)
        }
        else
        {
          setImages(image);
          const organizedData = organizeData(image);
          console.log(organizedData);
          setGalleryData(organizedData);
        }
        const output = await authenticate();
    setAuthenticated(output);
      } catch (error) {
        setEmptyarray(true)
        console.error("Error in gallery:", error);
      }
      finally{
        setLoading(false)
      }
    };
  
    fetchData();
  }, []);
  const organizeData = (data) => {
    const organizedData = new Map();
    data.forEach((image) => {
      const { title } = image;
      if (!organizedData.has(title)) {
        organizedData.set(title, [image]);
      } else {
        organizedData.get(title).push(image);
      }
    });
    return organizedData;
  };
  return (
    <ThemeProvider>
  {loading ?(
    <div style={{
      height:"100%",
      width:"100%"
    }}>
      <Loader progress="60%" />
    </div>
    
  ) : (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: "5px",
      }}
    >
      {authenticated && (
        <div style={{
          display:"flex",
          alignItems:"center",
          flexDirection:"column",
          marginBottom:"30px"
        }}>
          <Link to="/private/addgallery">
          <Button icon="add-folder" onClick={function _a() {}}>
            Add Images
          </Button>
          </Link>
        </div>
        
       
      )}

      {emptyarray === false ?(
        [...galleryData.entries()].map(([title, images]) => (
          <>
            <Carousel routes={"galleryImgpage"} images={images} />
            <br />
          </>
        ))
       
      ):
      <IllustratedMessage
      subtitle={{}}
      subtitleText="This will be updated soon sorry for inconvenience"
      titleText="No Images"
    />
      }
    </div>
  )}
  </ThemeProvider>
    
  );
};

export default Gallery;