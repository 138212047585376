import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./container/Home";
import News from "./container/News";
import Researcharea from "./container/Researcharea";
import Researchpublications from "./container/Researchpublications ";
import Groupmember from "./container/Groupmember";
import Pipage from "./container/Pipage";
import Joinus from "./container/Joinus";
import Gallery from "./container/Gallery";
import Contactus from "./container/Contactus";
import Navbar from "./components/Navbar";
import Addmember from "./components/Addmember";
import Privateroutes from "./components/Privateroutes";
import Addgallery from "./components/Addgallery";
import Deleteimage from "./components/Deleteimage";
import Createpost from "./components/Createpost";
import Postpage from "./components/Postpage";
import Sidemenu from "./components/Sidemenu";
import Notifications from "./container/Notifications";
import CreateResearchArea from "./components/CreateResearchArea";
import Addpublication from "./components/Addpublication";
import Addpipageimg from "./components/Addpipageimg";
import Addpipageaward from "./components/Addpipageaward";
import Researchpage from "./components/Researchpage";
import Addpi from "./components/Addpi";
import Loginpage from "./components/Loginpage";
import Registerpage from "./components/Registerpage";
import GalleryImagpage from "./container/GalleryImgpage";
import Updatemember from "./components/Updatemember";
import Updatepi from "./components/Updatepi";
import Updateresearch from "./components/Updateresearch";
import Updatepost from "./components/Updatepost";
import Memberpage from "./components/Memberpage";

const YourComponent = () => {
  const boxStyle = {
    flex:`1`,
    overflowX: `hidden`,
     width:`100%`,
    borderRadius: `0.5rem`,
    boxShadow:
              `0 0 0.125rem 0 color-mix(in srgb, var(--sapContent_ShadowColor) 16%, transparent),
              0 0.5rem 1rem 0 color-mix(in srgb, var(--sapContent_ShadowColor) 16%, transparent)`,
    margin:`0px 10px`,
    height:"100vh",
    overflowY:"auto"
  };
  // console.log(process.env.REACT_APP_BACKEND_URL)
  return (
    <Router>
        <Navbar />
        <div 
        style={{
          display:"flex",
          height: "100vh",
          width:"100%",
        }}
        >
          <Sidemenu/>
          <div className="content" style={boxStyle}>
          <Routes>
          <Route path="/private" element={<Privateroutes />}>
            <Route exact path="createpost" element={<Createpost />} />
            <Route exact path="createreseracharea" element={<CreateResearchArea />} />
            <Route exact path="addmembers" element={<Addmember />} />
            <Route exact path="addpi" element={<Addpi />} />
            <Route exact path="addgallery" element={<Addgallery />} />
            <Route exact path="addpublication" element={<Addpublication />} />
            <Route exact path="addpipageimg" element={<Addpipageimg />} />
            <Route exact path="addpipageaward" element={<Addpipageaward />} />
            <Route path="deleteimage/:id" element={<Deleteimage />} />
            <Route path="updatemember/:id" element={<Updatemember />} />
            <Route path="updatepi/:id" element={<Updatepi />} />
            <Route path="updateresearch/:id" element={<Updateresearch />} />
            <Route path="updatepost/:id" element={<Updatepost />} />
            <Route path="notifications" element={<Notifications />} />
          </Route>
          <Route path="/" element={<Home />} />
          <Route path="/news" element={<News />} />
          <Route path="/researcharea" element={<Researcharea />} />
          <Route path="/researchpublications" element={<Researchpublications />}/>
          <Route path="/groupmember" element={<Groupmember />} />
          <Route path="/pipage" element={<Pipage />} />
          <Route path="/joinus" element={<Joinus />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/contactus" element={<Contactus />} />
          <Route path="/postpage/:id" element={<Postpage />} />
          <Route path="/galleryImgpage/:id" element={<GalleryImagpage />} />
          <Route path="/researchpage/:id" element={<Researchpage />} />
          <Route path = "/login" element={<Loginpage/>}/>
          <Route path = "/register" element={<Registerpage/>}/>
          <Route path = "/memberpage/:id" element={<Memberpage/>}/>
        </Routes>
          </div>
        </div>
    </Router>
  );
};

export default YourComponent;
