import React from "react";
import { FileUploader, Button,TextArea} from "@ui5/webcomponents-react";
import { useState ,useEffect} from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ThemeProvider,Loader } from '@ui5/webcomponents-react';
import Alert from "./Alert";
import {ComboBox, ComboBoxItem, Icon ,CheckBox, DatePicker } from '@ui5/webcomponents-react';
const Addgallery = () => {
  const [filevalue, setFilevalue] = useState("");
  const [loading,setLoading] = useState(false);
  const [event, setEvent] = useState("");
  const navigate = useNavigate();
  const [alert,setAlert] = useState(null);
  const [galleryData, setGalleryData] = useState({});
  const [existingevent,setExistingevent] = useState(true);
  const [eventdate,setEventdate] = useState("");
  const showAlert = (message,type)=>{
    setAlert({
       mes:message,
       type:type,
    })
   setTimeout(() => {
           setAlert(null);
          //  window.location.reload();
   }, 1500);
}
useEffect(() => {
  const fetchData = async () => {
    try {
      fetch(`${process.env.REACT_APP_BACKEND_URL}/addgallery`).then((res) => {
    res.json().then((image) => {
      const organizedData = organizeData(image);
      setGalleryData(organizedData);
      console.log(organizedData)
      
    });
  });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  fetchData();
}, []);
const organizeData = (data) => {
  const organizedData = new Map();
  data.forEach((image) => {
    const { title } = image;
    if (!organizedData.has(title)) {
      organizedData.set(title, [image]);
    } else {
      organizedData.get(title).push(image);
    }
  });
  return organizedData;
};
  const sendimages = async (e) => {
    e.preventDefault();
    if ((!filevalue.length) || (!event && existingevent) || (!eventdate)){
      showAlert("Please fill all details","danger");
      return;
    }
    setLoading(true)
    const formdataimg = new FormData();
    const formdata = new FormData();
    formdataimg.append("file", filevalue[0]);
    formdataimg.append("upload_preset", "cdriimg");
    formdataimg.append("cloud_name", "drujdpuxv");
    
    try {
      const response = await axios.post(
        "https://api.cloudinary.com/v1_1/drujdpuxv/image/upload/",
        formdataimg
      );
    
      // Access the response data directly
      formdata.append("imglink",response.data.url);
      formdata.append("imgid",response.data.public_id);
    } catch (err) {
      console.error("Error uploading image:", err);
    }
    formdata.append("event", event);
    formdata.append("eventdate",eventdate);
    await axios.post(`${process.env.REACT_APP_BACKEND_URL}/addgallery`, formdata)
      .then((res) => {
        setLoading(false)
        navigate("/gallery");
      })
      .catch((err) => console.log(err));

  };
  return (
    <ThemeProvider>
  {loading ?(
    <div style={{
      height:"100%",
      width:"100%"
    }}>
      <Loader progress="60%" />
    </div>
    
  ) : (
    <div className="adddimage-content">
      <Alert alert={alert}/>
    <div className="addimg-content-inside">
    <CheckBox
        onChange={(e) => setExistingevent(!existingevent)}
        text="Click if you want to add in existing event"
        valueState="None"
        />
    {existingevent&&(<TextArea
      placeholder="Enter event details"
      rows={2}
      value={event}
      onChange={e=>setEvent(e.target.value)}
    />)
    }
    {!existingevent&&(
    <ComboBox
    icon={<Icon name="employee" />}
    onChange={e=>setEvent(e.target.value)}
    valueState={event}
    >
  {
    Array.from(galleryData).map(([key,value]) => (
      <ComboBoxItem text={key} />
    ))    
  }   
  </ComboBox>)
  }
  <DatePicker
        onChange={(e) => setEventdate(e.target.value)}
        onInput={function _a(){}}
        primaryCalendarType="Gregorian"
        value={eventdate}
        placeholder="Ex Sep 14, 2015"
        required
      />
    <FileUploader
        onChange={(e) => setFilevalue(e.target.files)}
        accept="image/*"
        required
      >
        <Button
          style={{
            color: "blue",
          }}
        >
          Your Image
        </Button>
      </FileUploader>
      <Button
      onClick={sendimages}
        style={{
          color: "blue",
        }}
      >
        Submit Image
      </Button>
    </div>
  </div>
  )}
  </ThemeProvider>
   
  );
};

export default Addgallery;
