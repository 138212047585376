import React from "react";

import "./container.css";
import Currentmembers from "../components/Currentmembers";
import Alumni from "../components/Alumni";
import { TabContainer,Tab } from '@ui5/webcomponents-react'; 
const Groupmember = () => {

    return(
      <TabContainer onTabSelect={function _a(){}}>
    <Tab
      icon="employee"
      selected
      text="Current Members"
    >
      <Currentmembers/>
    </Tab>
    <Tab
      icon="employee"
      text="Alumnis"
    >
      <Alumni/>
    </Tab>
    </TabContainer>
    )
};

export default Groupmember;
