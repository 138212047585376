import React from 'react'
import { TabContainer,Tab } from '@ui5/webcomponents-react'; 
import Mailpage from './Mailpage';
import Applicationpage from './Applicationpage';
import "@ui5/webcomponents-icons/dist/email.js";
import "@ui5/webcomponents-icons/dist/business-card.js";
const Notifications = () => {
  return (
    <TabContainer onTabSelect={function _a(){}}>
    <Tab
      icon="email"
      selected
      text="Mail"
    >
      <Mailpage/>
    </Tab>
    <Tab
      icon="business-card"
      text="Applications"
    >
      <Applicationpage/>
    </Tab>
  </TabContainer>
  )
}

export default Notifications