import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { Link } from 'react-router-dom';
import "@ui5/webcomponents-icons/dist/customer.js";
import { NotificationListGroupItem ,Avatar,NotificationAction,NotificationListItem} from '@ui5/webcomponents-react';
const Applicationpage =  () => {
    const [readapplications,setReadapplications] = useState([]);
    const [unseenapplications,setUnseenapplications] = useState([]);
    useEffect(()=>{
        const fetchData = async ()=>{
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/jobapplications`);
                const application = await response.json();
                const unseen = [];
                const read = [];
                
                application.forEach(application => {
                  if (application.flag === false) {
                    unseen.push(application);
                  } else {
                    read.push(application);
                  }
                });
        
              setUnseenapplications(unseen);
              setReadapplications(read);
              } catch (error) {
                console.error("Error:", error);
              }
        }
        fetchData();
    },[])
    const deletemail = async (_id)=>{
        await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/deleteapplication/${_id}`)
        .then(res=>{
            // navigate('/news')
            window.location.reload();
        }).catch(err=>{console.log(err)})
    }
    const acceptemail = async (_id) =>{
      try {
        const response = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/jobapplications`, { id: _id});
        window.location.reload();
      } catch (error) {
        console.error(error);
      }
    }
  return (
    <>
    <NotificationListGroupItem
  onClose={function _a(){}}
  onToggle={function _a(){}}
  titleText="Unread Applications"
  showCounter
>
{unseenapplications.length > 0 &&(unseenapplications.map((application) => 

<NotificationListItem
    actions={<><NotificationAction icon="accept" text="mark as read" onClick={(e)=>acceptemail(application._id)}/><NotificationAction icon="message-error" text="Delete" onClick={(e)=>deletemail(application._id)}/></>}
    avatar={<Avatar icon='customer' size="XS"></Avatar>}
    footnotes={<><span>{application.name}</span> <Link target="_blank" rel="noopener noreferrer" to={application.resumelink}>Resume</Link> <span>{new Date(application.createdAt).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}</span> <span>{application.email}</span></>}
    priority="Medium"
    titleText={`${application.jobrole}`}
  >
   {application.message}
  </NotificationListItem>


))}
  
</NotificationListGroupItem>


<NotificationListGroupItem
  onClose={function _a(){}}
  onToggle={function _a(){}}
  titleText="Read Applications"
  showCounter
>
{readapplications.length > 0 &&(readapplications.map((application) => 

<NotificationListItem
    actions={<NotificationAction icon="message-error" text="Delete" onClick={(e)=>deletemail(application._id)}/>}
    avatar={<Avatar icon='customer' size="XS"></Avatar>}
    footnotes={<><span>{application.name}</span> <Link target="_blank" rel="noopener noreferrer" to={application.resumelink}>Resume</Link> <span>{new Date(application.createdAt).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}</span> <span>{application.email}</span></>}
    priority="Medium"
    titleText={`${application.jobrole}`}
  >
   {application.message}
  </NotificationListItem>


))}
  
</NotificationListGroupItem>
</>
  )
}

export default Applicationpage