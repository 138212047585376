import React from "react";
import { useState,useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { SideNavigation ,SideNavigationItem} from '@ui5/webcomponents-react';
import "@ui5/webcomponents-icons/dist/accessibility.js";
import "@ui5/webcomponents-icons/dist/home.js";
import "@ui5/webcomponents-icons/dist/background.js";
import "@ui5/webcomponents-icons/dist/email.js";
import "@ui5/webcomponents-icons/dist/company-view.js";
import "@ui5/webcomponents-icons/dist/person-placeholder.js";
import "@ui5/webcomponents-icons/dist/course-book.js";
import "@ui5/webcomponents-icons/dist/visits.js";
import "@ui5/webcomponents-icons/dist/log.js";
import "@ui5/webcomponents-icons/dist/decision.js";
import "@ui5/webcomponents-icons/dist/newspaper.js";
import authenticate from "./Authfunction";
import handleToggle from "./Handletoggle";
const Sidemenu = () => {
  const [authenticated, setAuthenticated] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    const fetchData = async () => {
      const output = await authenticate();
      setAuthenticated(output);
    };

    fetchData();
  }, []);

  const logoutfun = () => {
    localStorage.removeItem("jwt token");
    navigate("/");
    setAuthenticated(false);
    window.location.reload();
  };
  const handleNavigation = (path) => {
    navigate(path);
    handleToggle();
  };
  return (
    <SideNavigation  style={{
      height:"100%",
      position:"absolute",
      left:"-300px",
      zIndex:"9999"
    }} 
    // collapsed
    onSelectionChange={function _a(){}}>
        <SideNavigationItem
        text="Home"
        icon="home"
        selected=""
        onClick={() => handleNavigation("/")}
      />
        <SideNavigationItem
        text="Principal Investigator"
        icon="person-placeholder"
        onClick={() => handleNavigation("/pipage")}
      />

      <SideNavigationItem
        text="Research Areas"
        icon="accessibility"
        onClick={() => handleNavigation("/researcharea")}
      />
      <SideNavigationItem
        text="Group Members"
        icon="company-view"
        onClick={() => handleNavigation("/groupmember")}
      />
      <SideNavigationItem
        text="Research Publications"
        icon="course-book"
        onClick={() => handleNavigation("/researchpublications")}
      />
      <SideNavigationItem
        text="Gallery"
        icon="background"
        onClick={() => handleNavigation("/gallery")}
      />
      <SideNavigationItem
        text="Contact us"
        icon="email"
        onClick={() => handleNavigation("/contactus")}
      />
      <SideNavigationItem
        text="News"
        icon="newspaper"
        onClick={() => handleNavigation("/news")}
      />
      <SideNavigationItem
        text="Join Us"
        icon="decision"
        // slot="fixedItems"
        onClick={() => handleNavigation("/joinus")}
      />
      {authenticated===false?(<SideNavigationItem
        text="Login"
        icon="visits"
        // slot="fixedItems"
        onClick={() => handleNavigation("/login")}
      />):
      (<SideNavigationItem
        text="Logout"
        icon="log"
        // slot="fixedItems"
        onClick={logoutfun}
      />)}
    </SideNavigation>
  );
};

export default Sidemenu;


