import React from 'react'
import authenticate from "../components/Authfunction.js";
import { useState,useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import "@ui5/webcomponents-icons/dist/add-folder.js";
import { IllustratedMessage,NotificationListGroupItem,NotificationAction,NotificationListItem,Button } from '@ui5/webcomponents-react';
const Pipageawards = () => {
    const [awards,setAwards] = useState("")
    const [authenticated, setAuthenticated] = useState(false);
    useEffect(()=>{
        const fetchData = async ()=>{
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/pipageaward`);
                const award = await response.json();
                setAwards(award);
                const output = await authenticate();
                setAuthenticated(output);
              } catch (error) {
                console.error("Error:", error);
              }
        }
        fetchData();
    },[])
    const deleteAward = async (_id)=>{
        await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/deletepipageaward/${_id}`)
        .then(res=>{
            console.log(res);
            window.location.reload();
        }).catch(err=>{console.log(err)})
    }
  return (
    <div style={{
        display:"flex",
        flexDirection:"column",
        alignItems:"center",
    }}>
        <div >
        {authenticated &&(
          <Link to="/private/addpipageaward">
            <Button icon="add-folder">
              Add Awards
            </Button>
          </Link>
    )}
        </div>
    <NotificationListGroupItem
  onClose={function _a(){}}
  onToggle={function _a(){}}
  titleText="Awards and Honors"
>
{awards.length > 0 ?(awards.map((award) => 
  <NotificationListItem
    actions={authenticated&&(<NotificationAction icon="message-error" text="Delete" onClick={(e)=>deleteAward(award._id)} />)}
    footnotes={<span>{new Date(award.creatingdate).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}</span>}
    titleText={award.title}
  >
  </NotificationListItem>
 )):(
    <IllustratedMessage
    subtitle={{}}
    subtitleText="This will be updated soon sorry for inconvenience"
    titleText="No Awards"
  />
  )}
</NotificationListGroupItem>

</div>
  )
}

export default Pipageawards