import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button } from "@ui5/webcomponents-react";
import authenticate from "../components/Authfunction.js";
import { IllustratedMessage } from '@ui5/webcomponents-react';
import Research from "../components/Research.js";
import "@ui5/webcomponents-icons/dist/add-folder.js";
import { ThemeProvider,Loader } from '@ui5/webcomponents-react';
const Researcharea = () => {
  const [posts, setPost] = useState([]);
  const [authenticated, setAuthenticated] = useState(false);
  const [loading,setLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/research`);
        const post = await response.json();
        setPost(post);
        setLoading(false)
        const output = await authenticate();
        setAuthenticated(output);
      } catch (error) {
        console.error("Error in researcharea page:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <ThemeProvider>
  {loading ?(
    <div style={{
      height:"100%",
      width:"100%"
    }}>
      <Loader progress="60%" />
    </div>
    
  ) : (
    <div>
      <div className="courses-div">
        {authenticated &&(
          <Link to="/private/createreseracharea">
            <Button icon="add-folder">
              Add Research
            </Button>
          </Link>
        )}
        {
        posts.length > 0 ?(posts.map((post) => <Research key={post.id} {...post} />))
        :(
          <IllustratedMessage
          subtitle={{}}
          subtitleText="This will be updated soon sorry for inconvenience"
          titleText="No Research areas"
        />
        )
      }
      </div>
    </div>
  )}
  </ThemeProvider>
    
  )
}

export default Researcharea