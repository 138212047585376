import React from "react";
import {Link} from 'react-router-dom'
export default function Blog ({_id,title,summary,blogwriter,coverimg,creatingdate
}) {

  return (
    <div className="courses-div-content">
      <div className="image-content">
        <Link to={`/postpage/${_id}`}>
        <img
          src={coverimg}
          alt="module img"
        />
        </Link>
      </div>
      <div className="text-content">
        <Link to={`/postpage/${_id}`}><h2>{title.length > 80 ? title.substring(0,77)+'...' :title} </h2></Link>
        <label className="date-author-details" >{blogwriter.length > 30 ? blogwriter.substring(0,30)+'...' :blogwriter}<span>{new Date(creatingdate).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}</span> </label>
        <label className="module-text">
          {summary.length > 200 ?summary.substring(0, 200)+'...':summary}
        </label>
      </div>
    </div>
  );
};

