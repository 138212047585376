import React, {  useState } from "react";
import { Button } from "@ui5/webcomponents-react";
import { useParams,useNavigate } from 'react-router-dom';
import axios from "axios";
import { ThemeProvider,Loader } from '@ui5/webcomponents-react';
const Deleteimage = () => {
  const [inputvalue, setInputvalue] = useState("");
  const [loading,setLoading] = useState(false);
  const {id} = useParams();
  const navigate = useNavigate();
  const deletefunc = async () => {
    if (inputvalue === "Delete") {
      setLoading(true)
        await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/private/deleteimage/${id}`)
        .then(res=>{
          setLoading(false)
            navigate('/gallery')
        }).catch(err=>{console.log(err)})
    }
  };
  return (
    <ThemeProvider>
  {loading ?(
    <div style={{
      height:"100%",
      width:"100%"
    }}>
      <Loader progress="60%" />
    </div>
    
  ) : (
    <div
    style={{
      width: "100%",
      height: "300px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <label className="delete-member-label">Enter "Delete" to confirm</label>
    <input
      type="text"
      onChange={(e) => setInputvalue(e.target.value)}
      value={inputvalue}
      className="delete-member-input"
      style={{
        border: inputvalue === "Delete" ? "2px solid green" : "2px solid red",
        outline: "none",
        margin:"10px 0px",
      }}
      onMouseOver={() => setInputvalue("")}
    />
    <Button
      style={{
        color: "blue",
        width: "150px",
      }}
      onClick={deletefunc}
    >
      Submit
    </Button>
  </div>
  )}
  </ThemeProvider>
    
  );
};

export default Deleteimage;

