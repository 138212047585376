// function authenticate() {
//     const retrievedData = localStorage.getItem("jwt token");
//     if(retrievedData === null){
//       return false;
//     }
//     return fetch(`${process.env.REACT_APP_BACKEND_URL}/protected-route`, {
//       method: "GET",
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: retrievedData ? `Bearer ${retrievedData}` : "",
//       },
//     })
//       .then((response) => response.json())
//       .then((data) => {
        
//         return data.status === "ok";
//       })
//       .catch((error) => {
//         console.error("Error:", error);
//         return false;
//       });
//   }
  
//   export default authenticate;
async function authenticate() {
  const retrievedData = localStorage.getItem("jwt token");
  if (retrievedData === null) {
    return false;
  }
  try {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/protected-route`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${retrievedData}`,
      },
    });

    if (!response.ok) {
      throw new Error("Failed to authenticate");
    }

    const data = await response.json();
    return data.status === "ok";
  } catch (error) {
    console.error("Authentication error:", error);
    return false;
  }
}

export default authenticate;
