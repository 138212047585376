import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Button,TextArea } from "@ui5/webcomponents-react";
import { ThemeProvider,Loader,DatePicker } from '@ui5/webcomponents-react';
import Alert from "./Alert";
const Addpipageaward = () => {
  const [award, setAward] = useState("");
  const [loading,setLoading] = useState(false);
  const navigate = useNavigate();
  const [alert,setAlert] = useState(null);
  const [creatingdate,setCreatingdata] = useState("");
  const showAlert = (message,type)=>{
    setAlert({
       mes:message,
       type:type,
    })
   setTimeout(() => {
           setAlert(null);
          //  window.location.reload();
   }, 1500);
}
  const sendimg = async (e) => {
    e.preventDefault();
    if ((!award) || (!creatingdate)){
      showAlert("Please fill the field","danger");
      return;
    }
    setLoading(true)
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/addpipageaward`, {title : award,creatingdate : creatingdate});
      setLoading(false)
      navigate("/pipage");
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <ThemeProvider>
    {loading ?(<Loader progress="60%" />):(
      <div className="adddmember-content">
        <Alert alert={alert}/>
        <form action="post">
        <TextArea
        onInput={function _a(){}}
        placeholder="Add Awards and Honors"
        value={award}
        onChange={(e) => setAward(e.target.value)}
        />
        <DatePicker
            onChange={(e) => setCreatingdata(e.target.value)}
            onInput={function _a(){}}
            primaryCalendarType="Gregorian"
            value={creatingdate}
            placeholder="Ex Sep 14, 2015"
            required
          />
        <Button onClick={sendimg}
          style={{
            color: "blue",
          }}
          >Submit </Button>
      </form>
    </div>
    )}
    </ThemeProvider>
  );
};

export default Addpipageaward 
