import React from 'react'
const Footer = () => {
  return (
    <div style={{
      textAlign:"center",
      width:"100%",
      fontWeight:"600",
      margin : "5px 5px",
    }}>© copyright all rights reserved</div>
  )
}

export default Footer