import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { FileUploader, Button } from "@ui5/webcomponents-react";
import { ThemeProvider,Loader } from '@ui5/webcomponents-react';
import Alert from "./Alert";
const Addpipageimg = () => {
  const [filevalue, setFilevalue] = useState("");
  const [loading,setLoading] = useState(false);
  const navigate = useNavigate();
  const [alert,setAlert] = useState(null);
  const showAlert = (message,type)=>{
    setAlert({
       mes:message,
       type:type,
    })
   setTimeout(() => {
           setAlert(null);
          //  window.location.reload();
   }, 1500);
}
  const sendimg = async (e) => {
    e.preventDefault();
    if (!filevalue.length){
      showAlert("Please select an image","danger");
      return;
    }
    setLoading(true)
    const formdataimg = new FormData();
    const formdata = new FormData();
    formdataimg.append("file", filevalue[0]);
    formdataimg.append("upload_preset", "cdriimg");
    formdataimg.append("cloud_name", "drujdpuxv");
    
    try {
      const response = await axios.post(
        "https://api.cloudinary.com/v1_1/drujdpuxv/image/upload/",
        formdataimg
      );
    
      // Access the response data directly
      formdata.append("imglink",response.data.url);
      formdata.append("imgid",response.data.public_id);
      
    } catch (err) {
      console.error("Error uploading image:", err);
    }

    const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/addpipageimg`, formdata)
      .then((res) => {
        
        setLoading(false)
        navigate("/pipage");
      })
      .catch((err) => console.log(err));
      
  };
  return (
    <ThemeProvider>
  {loading ?(
    <div style={{
      height:"100%",
      width:"100%"
    }}>
      <Loader progress="60%" />
    </div>
  ) : (
    <div className="adddmember-content">
        <Alert alert={alert}/>
        <form action="post">
        <FileUploader onChange={(e) => setFilevalue(e.target.files)} accept="image/*" required
         >
          <Button
          style={{
            color: "blue",
          }}
          >Your Profile Image</Button>
        </FileUploader>
        <Button onClick={sendimg}
          style={{
            color: "blue",
          }}
          >Submit Image</Button>
      </form>
    </div>
  )}
  </ThemeProvider>
    
    
  );
};

export default Addpipageimg;
