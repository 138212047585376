import React from 'react'
import { useState } from 'react';
import { useNavigate ,Link} from 'react-router-dom';
import { Form ,FormItem,FormGroup,Input,Label} from '@ui5/webcomponents-react';
import { Button} from "@ui5/webcomponents-react";
import axios from 'axios';
import Alert from  './Alert.js'
import { ThemeProvider,Loader } from '@ui5/webcomponents-react';
const Loginpage = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [alert,setAlert] = useState(null);
  const [loading,setLoading] = useState(false);
    const showAlert = (message,type)=>{
        setAlert({
           mes:message,
           type:type,
        })
       setTimeout(() => {
               setAlert(null);
               window.location.reload();
       }, 1500);
   }
  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!email.includes("@")) {
      showAlert("Invalid email", "danger");
      return;
    }
    setLoading(true)
    console.log(email)
    await axios.post(`${process.env.REACT_APP_BACKEND_URL}/login`,{email,password})
    .then(res => {
      const dataToStore = res.data.token
      localStorage.setItem('jwt token', dataToStore);  
      setLoading(false)
      navigate("/")  
      window.location.reload();
      
    }
    )
    .catch(err => {console.log(err)
      showAlert(`${err.message}`,"danger");
    });
  };
  return (
    <ThemeProvider>
  {loading ?(
    <div style={{
      height:"100%",
      width:"100%"
    }}>
      <Loader progress="60%" />
    </div>
    
  ) : (
    <div style={{
      height:"100%",
      width:"100%"
  }}>
    <Alert alert={alert}/>
    <Form
      backgroundDesign="Transparent"
      columnsL={1}
      columnsM={1}
      columnsS={1}
      columnsXL={2}
      labelSpanL={4}
      labelSpanM={2}
      labelSpanS={12}
      labelSpanXL={4}
      style={{
          alignItems: 'center',
          padding:"50px 50px"
      }}
      titleText="Login"
      >
<FormGroup titleText="">
  <FormItem label="Name">
    <Input required value={name} onChange={e=>setName(e.target.value)}/>
  </FormItem>
  <FormItem label={<Label required>Email</Label>}>
    <Input type="email" required value={email} onChange={e=>setEmail(e.target.value)}/>
  </FormItem>
  <FormItem label={<Label required>Password</Label>}>
    <Input type="password" required value={password} onChange={e=>setPassword(e.target.value)}/>
  </FormItem>
  <FormItem>
    <Link to="/register" style={{textDecoration:"none"}}>Create new Admin</Link>
  </FormItem>
</FormGroup>
</Form>


  <div style={{
      display:"flex",
      flexDirection:"column",
      alignItems:"center",
      height:"100px",
      width:"100%",
  }}>
 <Button  onClick={handleSubmit}
   style={{
     color: "blue",
     width:"90px",
   }}
   >Submit</Button>
  </div>
  </div>
  )}
  </ThemeProvider>
    
  );
};

export default Loginpage;