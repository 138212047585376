import React from 'react'
import { useState } from 'react';
import { Form ,FormItem,FormGroup,Input,Label,TextArea} from '@ui5/webcomponents-react';
import { Button} from "@ui5/webcomponents-react";
import axios from 'axios';
import Alert from  '../components/Alert.js'
import { ThemeProvider,Loader } from '@ui5/webcomponents-react';
const Application = (props) => {
    const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [resumelink,setResumelink] = useState("");
  const [alert,setAlert] = useState(null);
  const [loading,setLoading] = useState(false);
    const showAlert = (message,type)=>{
        setAlert({
           mes:message,
           type:type,
        })
       setTimeout(() => {
               setAlert(null);
               window.location.reload();
       }, 1500);
   }
   const handleSubmit = async (e) => {
    e.preventDefault();
    const subject = encodeURIComponent(`Application for ${props.jobRole}`);
      const body = encodeURIComponent(`Message:\n${message}\n Resume Link:\n ${resumelink}`);
      window.location.href = `mailto:2105369@kiit.ac.in?subject=${subject}&body=${body}`;
    setLoading(true)
    if((!name) || (!email) || (!resumelink))
    {
      showAlert("Please fill all the fields","danger");
      return;
    }
    if (!email.includes("@")) {
      showAlert("Invalid email", "danger");
      return;
    }
    const jobrole = props.jobRole
    await axios.post(`${process.env.REACT_APP_BACKEND_URL}/jobapplications`,{name,email,message,resumelink,jobrole})
    .then(res => {
      if(res.data.status === 'ok')
      {
        setLoading(false)
        showAlert("Application sent successfully","success");
      }
      else
      {
        showAlert(`${res.data.error}`,"danger");
      }
        
    }
    )
    .catch(err => {console.log(err)
      showAlert(`${err.message}`,"danger");
    });
  };
  return (
    <ThemeProvider>
  {loading ?(
    <div style={{
      height:"100%",
      width:"100%"
    }}>
      <Loader progress="60%" />
    </div>
    
  ) : (
    <div style={{
      height:"100%",
      width:"100%",
  }}>
    <Alert alert={alert}/>
    <Form
      backgroundDesign="Transparent"
      columnsL={1}
      columnsM={1}
      columnsS={1}
      columnsXL={2}
      labelSpanL={4}
      labelSpanM={2}
      labelSpanS={12}
      labelSpanXL={4}
      style={{
          alignItems: 'center'
      }}
      titleText="Application Form"
      >
<FormGroup titleText="">
  <FormItem label={<Label required>Name</Label>}>
    <Input required value={name} onChange={e=>setName(e.target.value)}/>
  </FormItem>
  <FormItem label={<Label required>Email</Label>}>
    <Input type="email" required value={email} onChange={e=>setEmail(e.target.value)}/>
  </FormItem>
  <FormItem label={<Label required>Resume Drive Link</Label>}>
    <Input required value={resumelink} onChange={e=>setResumelink(e.target.value)}/>
  </FormItem>
  <FormItem label={<Label style={{alignSelf: 'start', paddingTop: '0.25rem'}}>Additional Comment</Label>}>
    <TextArea
      required
      placeholder="Tell somthing about yourself"
      rows={5}
      value={message}
      onChange={e=>setMessage(e.target.value)}
    />
  </FormItem>
</FormGroup>
</Form>
<div style={{
    display:"flex",
    flexDirection:"column",
    alignItems:"center",
    height:"100px",
    width:"100%",
}}>
<Button  onClick={handleSubmit}
 style={{
   color: "blue",
   width:"90px",
 }}
 >Apply</Button>
</div>
  </div>
  )}
  </ThemeProvider>
    
  )
}

export default Application