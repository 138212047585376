import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { FileUploader, Button } from "@ui5/webcomponents-react";
import { ThemeProvider,Loader } from '@ui5/webcomponents-react';
import { ComboBox,ComboBoxItem,Icon } from '@ui5/webcomponents-react';
import "./comp.css";
import Alert from "./Alert";
import { DatePicker } from '@ui5/webcomponents-react';
import "@ui5/webcomponents-localization/dist/features/calendar/Gregorian.js";
const Addmember = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [currentposition, setCurrentposition] = useState("");
  const [joiningdate,setJoiningdate] = useState("");
  const [cvlink, setCvlink] = useState("");
  const [filevalue, setFilevalue] = useState("");
  const [membertype,setMembertype] = useState("");
  const [loading,setLoading] = useState(false);
  const [alert,setAlert] = useState(null);
  const [expertise, setExpertise] = useState("");
  const navigate = useNavigate();
  const showAlert = (message,type)=>{
    setAlert({
       mes:message,
       type:type,
    })
   setTimeout(() => {
           setAlert(null);
          //  window.location.reload();
   }, 1500);
}
  const sendpost = async (e) => {
    e.preventDefault();
    if((!name) || (!email) || (!currentposition) || (!cvlink) || (!filevalue) || (!membertype) || (!expertise) || (!joiningdate))
    {
      showAlert("Please fill all the fields","danger");
      return;
    }
    if (!email.includes("@")) {
      showAlert("Invalid email", "danger");
      return;
    }
    setLoading(true)
    const formdataimg = new FormData();
    const formdata = new FormData();
    formdataimg.append("file", filevalue[0]);
    formdataimg.append("upload_preset", "cdriimg");
    formdataimg.append("cloud_name", "drujdpuxv");
    try {
      const response = await axios.post(
        "https://api.cloudinary.com/v1_1/drujdpuxv/image/upload/",
        formdataimg
      );
    
      // Access the response data directly
      formdata.append("imglink",response.data.url);
      formdata.append("imgid",response.data.public_id);
    } catch (err) {
      console.error("Error uploading image:", err);
    }
    
    
    formdata.append("name", name);
    formdata.append("email", email);
    formdata.append("currentposition", currentposition);
    formdata.append("cvlink", cvlink);
    formdata.append("membertype", membertype);
    formdata.append("expertise", expertise);
    formdata.append("joiningdate",joiningdate)
    const response = await axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/addmember`, formdata)
      .then((res) => {
        setLoading(false)
        navigate("/groupmember");
      })
      .catch((err) => {console.log(err);
        showAlert(`${err.message}`,"danger");});
    console.log(response);
  };

  return (
    <ThemeProvider >
  {loading ?(
    <div style={{
      height:"100%",
      width:"100%"
    }}>
      <Loader progress="60%" />
    </div>
  ) : (
    <div className="adddmember-content">
      <Alert alert={alert}/>
    <form action="post">
      <input
        type="name"
        placeholder="Name"
        value={name}
        required
        onChange={(e) => setName(e.target.value)}
      />
      <input
        type="email"
        placeholder="Email"
        value={email}
        required
        onChange={(e) => setEmail(e.target.value)}
      />
      <input
        type="text"
        placeholder="Current Position"
        value={currentposition}
        required
        onChange={(e) => setCurrentposition(e.target.value)}
      />
      <input
        type="text"
        placeholder="Expertise"
        value={expertise}
        required
        onChange={(e) => setExpertise(e.target.value)}
      />
        <DatePicker
        onChange={(e) => setJoiningdate(e.target.value)}
        onInput={function _a(){}}
        primaryCalendarType="Gregorian"
        value={joiningdate}
        placeholder="Ex Sep 14, 2015"
        required
      />
      <input
        type="text"
        placeholder="Drive link for Cv"
        value={cvlink}
        required
        onChange={(e) => setCvlink(e.target.value)}
      />
      
      <FileUploader onChange={(e) => setFilevalue(e.target.files)} accept="image/*" required
       >
        <Button
        style={{
          color: "blue",
        }}
        >Your Profile Image</Button>
      </FileUploader>
      <ComboBox
      icon={<Icon name="employee" />}
      onChange={e=>setMembertype(e.target.value)}
      onInput={function _a(){}}
      onSelectionChange={function _a(){}}
      value={membertype}
    >
      <ComboBoxItem text="Principal Investigator" />
      <ComboBoxItem text="Current Members" />
      <ComboBoxItem text="Alumni" />
    </ComboBox>
      <Button onClick={sendpost}
        style={{
          color: "blue",
        }}
        >Submit Post</Button>
    </form>
  </div>
  )}
  </ThemeProvider>
);
};

export default Addmember;
