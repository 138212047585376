import React from 'react'; 
import { TabContainer,Tab } from '@ui5/webcomponents-react'; 
import Pipageimg from './Pipageimg';
import Pipageawards from './Pipageawards';
import "@ui5/webcomponents-icons/dist/background.js";
import "@ui5/webcomponents-icons/dist/competitor.js";
import "@ui5/webcomponents-icons/dist/company-view.js";
import Pidetails from './Pidetails';
const Pipage = () => {
  return (
    <div style={{
      height:"100%",
      width:"100%",
    }}>
      <TabContainer onTabSelect={function _a(){}} >
      <Tab
        icon="company-view"
        selected
        text="Principal Investigator"
      >
        <Pidetails/>
       </Tab>
       <Tab
        icon="competitor"
        text="Awards and Honors"
      >
        <Pipageawards/>
       </Tab>
       <Tab
        icon="background"
        text="Images"
      >
        <Pipageimg/>
       </Tab>
  </TabContainer>
    </div>
  );
};

export default Pipage;
