import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { TextArea, Button } from "@ui5/webcomponents-react";
import { ThemeProvider,Loader,DatePicker } from '@ui5/webcomponents-react';
import Alert from "./Alert";
const Addpublication = () => {
  const [publicationlink, setPublicationlink] = useState("");
  const [publication, setPublication] = useState("");
  const [publicationdate,setPublicationdate] = useState("");
  const [loading,setLoading] = useState(false);
  const navigate = useNavigate();
  const [alert,setAlert] = useState(null);
  const showAlert = (message,type)=>{
    setAlert({
       mes:message,
       type:type,
    })
   setTimeout(() => {
           setAlert(null);
          //  window.location.reload();
   }, 1500);
}
  const sendpost = async (e) => {
    e.preventDefault();
    if((!publicationlink) || (!publication) || (!publicationdate))
    {
      showAlert("Please fill all the fields","danger");
      return;
    }
    setLoading(true)
    const formdata = new FormData();
    formdata.append("publicationlink", publicationlink);
    formdata.append("publication", publication);
    formdata.append("publicationdate",publicationdate);
    
   await axios.post(`${process.env.REACT_APP_BACKEND_URL}/addpublication`, formdata)
      .then((res) => {
        
        setLoading(false)
        navigate("/researchpublications");
      })
      .catch((err) => console.log(err));
      
  };
  return (
    <ThemeProvider>
  {loading ?(
    <div style={{
      height:"100%",
      width:"100%"
    }}>
      <Loader progress="60%" />
    </div>
  ) : (
    <div className="adddmember-content">
      <Alert alert={alert} />
        <form action="post">
        <TextArea
        placeholder="Enter Publication"
        rows={2}
        value={publication}
        onChange={e=>setPublication(e.target.value)}
      />
      <TextArea
        placeholder="Enter Publication Link"
        rows={1}
        value={publicationlink}
        onChange={e=>setPublicationlink(e.target.value)}
      />
      <DatePicker
        onChange={(e) => setPublicationdate(e.target.value)}
        onInput={function _a(){}}
        primaryCalendarType="Gregorian"
        value={publicationdate}
        placeholder="Ex Sep 14, 2015"
        required
      />
        <Button onClick={sendpost}
          style={{
            color: "blue",
          }}
          >Submit Post</Button>
      </form>
    </div>
  )}
  </ThemeProvider>
    
  );
};

export default Addpublication;
