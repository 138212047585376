import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import authenticate from "../components/Authfunction";
import { Button } from "@ui5/webcomponents-react";
import { IllustratedMessage } from "@ui5/webcomponents-react";
import "@ui5/webcomponents-icons/dist/add-folder.js";
import { Popover, Label } from "@ui5/webcomponents-react";
import axios from "axios";
const Pipageimg = () => {
  const [authenticated, setAuthenticated] = useState(false);
  const [pipageimgs, setPipageimgs] = useState("");
  const [popoverIsOpen, setPopoverIsOpen] = useState(false);
  const [inputvalue, setInputvalue] = useState("");
  const deletefunc = async (_id) => {
    if (inputvalue === "Delete") {
      //   console.log("Deleted");
      await axios
        .delete(`${process.env.REACT_APP_BACKEND_URL}/deletepipageimg/${_id}`)
        .then((res) => {
          console.log(res);
          window.location.reload();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      
      try {
         const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/pipageimg`)
         const pipageimg = await response.json();
          setPipageimgs(pipageimg);
           const output = await authenticate();
          setAuthenticated(output);
      } catch (error) {
        console.error("Error in pipageimg page:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: "5px",
      }}
    >
      {authenticated && (
        <Link to="/private/addpipageimg">
          <Button icon="add-folder">Add Images</Button>
        </Link>
      )}
      {pipageimgs.length > 0 ? (
        <div className="cards">
          {pipageimgs.map((pipageimg) => (
            <div className="card">
              {authenticated && (
                <div>
                  <Button
                    id={"openPopoverBtn3"}
                    onClick={() => {
                      setPopoverIsOpen(true);
                    }}
                  >
                    Delete member
                  </Button>
                  <Popover
                    opener={"openPopoverBtn3"}
                    headerText="Enter Delete to confirm"
                    horizontalAlign="Center"
                    placementType="Bottom"
                    open={popoverIsOpen}
                    onAfterClose={() => {
                      setPopoverIsOpen(false);
                    }}
                  >
                    <Label>
                      <input
                        type="text"
                        onChange={(e) => setInputvalue(e.target.value)}
                        value={inputvalue}
                        className="delete-member-input"
                        style={{
                          marginRight: "5px",
                          width: "90px",
                          border:
                            inputvalue === "Delete"
                              ? "2px solid green"
                              : "2px solid red",
                          outline: "none",
                          margin: "10px 0px",
                        }}
                        onMouseOver={() => setInputvalue("")}
                      />
                      <Button
                        style={{
                          color: "blue",
                          width: "80px",
                          height: "34px",
                        }}
                        onClick={(e) => deletefunc(pipageimg._id)}
                      >
                        Submit
                      </Button>
                    </Label>
                  </Popover>
                </div>
              )}
              <img src={pipageimg.coverimg} alt="Publication name" />
            </div>
          ))}
        </div>
      ) : (
        <IllustratedMessage
          subtitle={{}}
          subtitleText="This will be updated soon. Sorry for the inconvenience."
          titleText="No Images"
        />
      )}
    </div>
  );
};

export default Pipageimg;
