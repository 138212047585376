import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Button } from "@ui5/webcomponents-react";
import { Grid } from "@ui5/webcomponents-react";
import { Card, List, StandardListItem } from "@ui5/webcomponents-react";
import authenticate from "./Authfunction";
import { IllustratedMessage } from "@ui5/webcomponents-react";
import { Popover, Label } from "@ui5/webcomponents-react";
import axios from "axios";
import { ThemeProvider,Loader} from '@ui5/webcomponents-react';

const Currentmembers = () => {
  const [authenticated, setAuthenticated] = useState(false);
  const [members, setMembers] = useState([]);
  const [emptyarray, setEmptyarray] = useState(false);
  const [inputvalue, setInputvalue] = useState("");
  const [openPopoverMemberId, setOpenPopoverMemberId] = useState(null);
  const [loading,setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/addmember/currentposition`);
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const memberData = await response.json();
  
        if (Array.isArray(memberData) && memberData.length === 0) {
          setEmptyarray(true);
        } else {
          setMembers(memberData);
        }
  
        const isAuthenticated = await authenticate();
        setAuthenticated(isAuthenticated);
  
      } catch (error) {
        setEmptyarray(true);
        console.error("Error in currentmembers:", error);
      } finally {
        setLoading(false); 
      }
    };
  
    fetchData();
  }, []);
  ;

  const deletefunc = async (_id) => {
    if (inputvalue === "Delete") {
      console.log("Deleted");
      await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/private/deletemember/${_id}`)
        .then(() => {
          window.location.reload();
          setMembers(members.filter(member => member._id !== _id));
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const togglePopover = (memberId) => {
    setOpenPopoverMemberId(openPopoverMemberId === memberId ? null : memberId);
  };

  return (
    <ThemeProvider>
  {loading ?(
    <div style={{
      height:"100%",
      width:"100%"
    }}>
      <Loader progress="60%" />
    </div>
    
  ) : (
    <div className="groupmember-main-div">
    <div className="gm-top-content-div">
      {authenticated && (
        <Link to="/private/addmembers">
          <Button icon="employee" onClick={() => {}}>
            Add Members
          </Button>
        </Link>
      )}
    </div>
    {!emptyarray ? (
      <div className="grid-div">
        <Grid className="member-grid" hSpacing="7rem" >
          {members.map((member) => (
            <React.Fragment key={member._id}>
              <div
                className="member-card"
                style={{
                  backgroundColor: "white",
                  borderRadius: "20px",
                  boxShadow: "0 0 10px 2px rgba(0,0,0,0.2)",
                }}
              >
                {authenticated && (
                  <div>
                    <Button
                      id={`openPopoverBtn${member._id}`}
                      onClick={() => togglePopover(member._id)}
                    >
                      Delete member
                    </Button>
                    <Popover
                      opener={`openPopoverBtn${member._id}`}
                      headerText="Enter Delete to confirm"
                      horizontalAlign="Center"
                      placementType="Bottom"
                      open={openPopoverMemberId === member._id}
                      onAfterClose={() => togglePopover(member._id)}
                    >
                      <Label>
                        <input
                          type="text"
                          onChange={(e) => setInputvalue(e.target.value)}
                          value={inputvalue}
                          className="delete-member-input"
                          style={{
                            marginRight: "5px",
                            width: "90px",
                            border:
                              inputvalue === "Delete"
                                ? "2px solid green"
                                : "2px solid red",
                            outline: "none",
                            margin: "10px 0px",
                          }}
                          onMouseOver={() => setInputvalue("")}
                        />
                        <Button
                          style={{
                            color: "blue",
                            width: "80px",
                            height: "34px",
                          }}
                          onClick={() => deletefunc(member._id)}
                        >
                          Submit
                        </Button>
                      </Label>
                    </Popover>
                    <Link to={`/private/updatemember/${member._id}`}>
                    <Button >
                      Edit
                    </Button>
                    </Link>
                    
                  </div>
                )}
                <a href={`/memberpage/${member._id}`} style={{width:"100%",
                      height:"100% ",textDecoration:"none",
                      }}>
                <div className="member-details">
                  <div className="member-img">
                    <img
                      src={member.coverimg}
                      alt="member img"
                    />
                  </div>
                  <Card
                    style={{
                      width: "100%",
                    }}
                  >
                    <List>
                      <StandardListItem description={member.name}>
                        Name
                      </StandardListItem>
                      <StandardListItem description={member.email}>
                        Email
                      </StandardListItem>
                      <StandardListItem
                        description={member.currentposition}
                      >
                        Position
                      </StandardListItem>
                      <StandardListItem
                        description={member.expertise}
                      >
                        Expertise
                      </StandardListItem>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={member.cvlink}
                      >
                        <StandardListItem description="Link">
                          CV/Resume
                        </StandardListItem>
                      </a>
                    </List>
                  </Card>
                </div>
                </a>
              </div>
            </React.Fragment>
          ))}
        </Grid>
      </div>
    ) : (
      <IllustratedMessage
        subtitle={{}}
        subtitleText="This will be updated soon sorry for inconvenience"
        titleText="No current members"
      />
    )}
  </div>
  )}
  </ThemeProvider>
   
  );
};

export default Currentmembers;