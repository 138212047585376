import React, { useState, useEffect, useRef } from "react";
import CELLS from "vanta/dist/vanta.cells.min";
import * as THREE from "three";
import cdrihome from '../images/divyasingh.jpg';
import { IllustratedMessage } from '@ui5/webcomponents-react';
import Carousel from '../container/Carousel.js';
import Footer from '../container/Footer.js';
import {ThemeProvider, Loader } from '@ui5/webcomponents-react';

const Home = () => {
  const [vantaEffect, setVantaEffect] = useState(null);
  const vantaRef = useRef(null);
  const [posts, setPosts] = useState([]);
  const [researches, setResearches] = useState([]);
  const [loadingPosts, setLoadingPosts] = useState(true);
  const [loadingResearches, setLoadingResearches] = useState(true);

  useEffect(() => {
    if (!vantaEffect) {
      setVantaEffect(
        CELLS({
          el: vantaRef.current,
          THREE: THREE,
          mouseControls: true,
          touchControls: true,
          gyroControls: false,
          scale: 1.0,
          scaleMobile: 1.0,
          color1: 0x15c7c7,
          color2: 0xaba416,
          size: 2.60,
          speed: 1.30,
        })
      );
    }
    return () => {
      if (vantaEffect) vantaEffect.destroy();
    };
  }, [vantaEffect]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/post`);
        const post = await response.json();
        setPosts(post);
        setLoadingPosts(false);
      } catch (error) {
        console.error("Error in home page news section:", error);
      }
    };

    fetchData();
  },[]);
  useEffect(() => {
    const fetchData2 = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/research`);
        const research = await response.json();
        setResearches(research);
        setLoadingResearches(false);
      } catch (error) {
        console.error("Error in home page research section:", error);
      }
    };

    fetchData2();
  },[]);

  return (
    <ThemeProvider>
    <div className="home-page">
      <div ref={vantaRef} className="moving-3d-background">
        <h1 className="main-text">Laboratory of Bone Biology - “Bone Appetite”</h1>
      </div>
      <div className="content-div-home">
        <div className="content-div-img">
          <img src={cdrihome} alt="bone img" />
        </div>
        <div className="content-div-text">
          <p>
            <strong>
              Osteoporosis is an asymptomatic systemic disease of the skeleton characterized by decreased bone mass and loss of microarchitectural integrity. One out of eight males and one out of three females suffer from osteoporosis, making India one of the largest affected countries in the world. Most of the drugs available are anti-resorptive agents which though prevent resorption of bone but do not increase bone mass. One of the very few anabolic agents approved by the FDA is PTH but it is very expensive and is the last line of therapy due to associated risk of osteosarcoma. Thus, we need newer cost-effective bone anabolic agents with minimum side effects. Focus of our laboratory is to identify and characterize new synthetic or natural source-derived CDRI compounds with potent osteogenic activity using various in vitro cell culture-based assays and in vivo animal models.
            </strong>
          </p>
          <p>
            Among the other aspects we work upon include the new and emerging field of osteoimmunology as there is increasing evidence that bone resorption and probably bone formation are modified by the immune system. Studies in our laboratory focus on the role of various pro-inflammatory and anti-inflammatory factors in the pathogenesis of osteoporosis and their effect on the Th17/T regulatory cell balance. In another ongoing project, we are focusing on the identification and characterization of novel microRNA candidates playing a regulatory role in osteoblast functions.
          </p>
        </div>
      </div>
      <div className="carousel-section">
        <div className="news-content">
          <h1 className="news-title carousel-text">News</h1>
          {loadingPosts ? (
            <Loader
            progress="40%"
            type="Indeterminate"
          />
          ) : posts.length > 0 ? (
            <Carousel routes={"postpage"} images={posts} />
          ) : (
            <IllustratedMessage
              subtitle={{}}
              subtitleText="This will be updated soon sorry for inconvenience"
              titleText="No news"
            />
          )}
        </div>
        <div className="research-content">
          <h1 className="research-text carousel-text">Research</h1>
          {loadingResearches ? (
            <Loader
            progress="40%"
            type="Indeterminate"
          />
          )  : researches.length > 0 ? (
            <Carousel routes={"researchpage"} images={researches} />
          ) : (
            <IllustratedMessage
              subtitle={{}}
              subtitleText="This will be updated soon sorry for inconvenience"
              titleText="No Research"
            />
          )}
        </div>
      </div>
      <Footer />
    </div>
    </ThemeProvider>
  );
};

export default Home;
