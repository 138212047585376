import React from "react";
import { useState } from 'react'
import axios from 'axios'
import { useNavigate,Link } from 'react-router-dom';
import { Form ,FormItem,FormGroup,Input,Button,Label} from '@ui5/webcomponents-react';
import Alert from  './Alert.js'
import { ThemeProvider,Loader } from '@ui5/webcomponents-react';
const Registerpage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [secretkey,setSecretkey] = useState("");
  const [alert,setAlert] = useState(null);
  const [loading,setLoading] = useState(false);
    const showAlert = (message,type)=>{
        setAlert({
           mes:message,
           type:type,
        })
       setTimeout(() => {
               setAlert(null);
       }, 1500);
   }
  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    if((!email) || (!secretkey) || (!password))
    {
      showAlert("Please fill all the fields","danger");
      return;
    }
    if (!email.includes("@")) {
      showAlert("Invalid email", "danger");
      return;
    }
    setLoading(true)
    await axios.post(`${process.env.REACT_APP_BACKEND_URL}/register`,{email,password,secretkey})
    .then(res => {
      console.log(res);
      if(res.data.status === 'error')
      {
        showAlert(`${res.data.error}`,"danger");
        return;
      }
      const dataToStore = res.data.token
      localStorage.setItem('jwt token', dataToStore); 
      setLoading(false)
      navigate("/")
      window.location.reload();
    }
    )
    .catch(err => {console.log(err)
      showAlert(`${err.message}`,"danger");
    });
  };
  return (
    <ThemeProvider>
  {loading ?(
    <div style={{
      height:"100%",
      width:"100%"
    }}>
      <Loader progress="60%" />
    </div>
    
  ) : (
    <div style={{
      height:"100%",
      padding:"50px 50px"
  }}>
    <Alert alert={alert}/>
    <Form
      backgroundDesign="Transparent"
      columnsL={1}
      columnsM={1}
      columnsS={1}
      columnsXL={2}
      labelSpanL={4}
      labelSpanM={2}
      labelSpanS={12}
      labelSpanXL={4}
      style={{
          alignItems: 'center'
      }}
      titleText="Register"
      >
<FormGroup titleText="">
  <FormItem label={<Label required>Email</Label>}>
    <Input type="email" required value={email} onChange={e=>setEmail(e.target.value)}/>
  </FormItem>
  <FormItem label={<Label required>Password</Label>}>
    <Input type="password" required value={password} onChange={e=>setPassword(e.target.value)}/>
  </FormItem>
  <FormItem label={<Label required>Secret Key</Label>}>
    <Input type="text" required value={secretkey} onChange={e=>setSecretkey(e.target.value)}/>
  </FormItem>
  <FormItem>
  <Link to="/login" style={{textDecoration:"none"}}>Already have an account?</Link>
  </FormItem>
</FormGroup>
</Form>


  <div style={{
      display:"flex",
      flexDirection:"column",
      alignItems:"center",
      height:"100px",
      width:"100%",
  }}>
 <Button  onClick={handleSubmit}
   style={{
     color: "blue",
     width:"90px",
   }}
   >Submit</Button>
  </div>
  </div>
  )}
  </ThemeProvider>
    
  );
};

export default Registerpage;
