import React, { useState,useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { ThemeProvider,Loader } from '@ui5/webcomponents-react';
import { Card,List,StandardListItem} from '@ui5/webcomponents-react';
const Memberpage = () => {
   const {id} = useParams();
   const [memberinfo,setMemberinfo] = useState([]);
   const [loading,setLoading] = useState(true)
   useEffect(()=>{
    const fetchData = async () => {
    fetch(`${process.env.REACT_APP_BACKEND_URL}/member/${id}`)
    .then(res => res.json())
    .then(data => 
      {
        setMemberinfo(data);
        // console.log(data);
        setLoading(false)
    })
    }
    fetchData();
},[])
  return (
    <ThemeProvider>
    {loading ?(
      <div style={{
        height:"100%",
        width:"100%"
      }}>
        <Loader progress="60%" />
      </div>
      
    ) : (
      <div style={{
        marginLeft:"30%",
        marginRight:"30%",
      }}>
    <div className="member-details">
      <div className="member-img" style={{
        width:"100%",
      }}>
        <img
          src={memberinfo.coverimg}
          alt="member img"
          style={{
            borderRadius:"0%",
            width:"100%",
          }}
        />
      </div>
     
      <Card
        style={{
          width: "100%",
        }}
      >
        <List>
          <StandardListItem description={memberinfo.name}>
          Name
          </StandardListItem>
          <StandardListItem description={memberinfo.email}>
            Email
          </StandardListItem>
          <StandardListItem
            description={memberinfo.currentposition}
          >
            Position
          </StandardListItem>
          <StandardListItem
      description={memberinfo.expertise}
    >
      Expertise
    </StandardListItem>
          <a target="_blank" rel="noopener noreferrer" href={memberinfo.cvlink}>
            <StandardListItem description="Link">
              CV/Resume
            </StandardListItem>
          </a>
        </List>
      </Card>
     
    </div>
    </div>

    )}
    </ThemeProvider>
  )
}

export default Memberpage