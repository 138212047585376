import React from "react";
import cdrilogo from "../images/cdri-logo.png";
import { useState, useEffect } from "react";
import {useNavigate } from "react-router-dom";
import "@ui5/webcomponents-fiori/dist/ShellBarItem.js";
import "@ui5/webcomponents-fiori/dist/ShellBar.js";
import "@ui5/webcomponents-icons/dist/menu2.js";
import authenticate from "./Authfunction";
import handleToggle from "./Handletoggle";
const Navbar = () => {
  const [authenticated, setAuthenticated] = useState(false);
  const [notificationcnt,setNotificationcnt] = useState(0);
  const navigate = useNavigate();
  useEffect(() => {
    const fetchData = async () => {
      const output = await authenticate();
      setAuthenticated(output);
    };
    const fetchmail = async () => {
      try {
        var notificationcnt = 0
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/contactus`);
        const mail = await response.json();
        mail.forEach(mail =>{
          if(mail.flag === false){
            notificationcnt++;
          }
        
        })
        const response2 = await fetch(`${process.env.REACT_APP_BACKEND_URL}/jobapplications`);
        const application = await response2.json();
        application.forEach(application =>{
          if(application.flag === false){
            notificationcnt++;
          }
        
        })
        
        setNotificationcnt(notificationcnt)
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchData();
    fetchmail();
  }, []);

  const handleNavigation = (path) => {
    navigate(path);
  };
  const Notificationclick = () => {
    
    navigate("/private/notifications");
  };
  return (
    <html xmlns="http://www.w3.org/1999/xhtml">
    <head>
      <style>
        {`
          ui5-shellbar::part(root) {
            width: 100%;
            padding-inline-start: 0.75rem;
            padding-inline-end: 1.25rem;
            border-radius: 0.5rem;
            box-shadow:
              0 0 0.125rem 0 color-mix(in srgb, var(--sapContent_ShadowColor) 16%, transparent),
              0 0.5rem 1rem 0 color-mix(in srgb, var(--sapContent_ShadowColor) 16%, transparent);
          }

          .tool-layout {
            padding: 0.5rem 0.5rem 0 0.5rem;
            background: color-mix(in srgb, black 4%, var(--sapBackgroundColor));
            display: grid;
            gap: 0.5rem;
            grid-template-rows: auto 1fr;
            grid-template-columns: auto 1fr;
          }

          .tool-layout > * {
            z-index: 1;
          }

          ui5-shellbar {
            grid-column: 1 / span 2;
            grid-row: 1 / 2;
          }

          

        `}
      </style>
    </head>
    <body xmlns="http://www.w3.org/1999/xhtml">
      <div className="tool-layout">
      <ui5-shellbar
            primary-title="Laboratory of Bone Biology"
            secondary-title="Bone Appetite"
          >
            <ui5-button
              // icon="fa-solid fa-bars"
              icon="menu2"
              slot="startButton"
              id="toggle"
              icon-only=""
              has-icon=""
              onClick={handleToggle}
            >
            </ui5-button>
            
            <img slot="logo" src={cdrilogo} />
            <ui5-shellbar-item icon="newspaper" text="news" onClick={() => handleNavigation("/news")}></ui5-shellbar-item>
            <ui5-shellbar-item icon="background" text="gallery" onClick={() => handleNavigation("/gallery")}></ui5-shellbar-item>
            {authenticated&&(<ui5-shellbar-item icon="bell" text="2 notifications" count={notificationcnt} onClick={Notificationclick}></ui5-shellbar-item>)}
          </ui5-shellbar>

        <style>
          {`
            ui5-side-navigation {
              height: 600px;
            }
          `}
        </style>
        
        </div>
    </body>
  </html>
  );
};

export default Navbar

