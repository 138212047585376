import React from 'react'
import { useEffect,useState } from 'react';
import { useParams,useNavigate,Link } from 'react-router-dom';
import { Button } from "@ui5/webcomponents-react";
import { Popover, Label } from "@ui5/webcomponents-react";
import axios from "axios";
import authenticate from "./Authfunction";
import { ThemeProvider,Loader } from '@ui5/webcomponents-react';
const Postpage = () => {
    const [authenticated, setAuthenticated] = useState(false);
    const [ postinfo,setPostinfo] = useState("");
    const {id} = useParams();
    const [popoverIsOpen, setPopoverIsOpen] = useState(false);
    const [inputvalue, setInputvalue] = useState("");
    const navigate = useNavigate();
    const [loading,setLoading] = useState(true);
    const deletefunc = async (id) => {
      if (inputvalue === "Delete") {
          await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/private/deletepost/${id}`)
          .then((res) => {
            setLoading(false)
            navigate('/news')
          })
          .catch((err) => {
            console.log(err);
          });
      }
    };
 
    useEffect(()=>{
      const fetchData = async () => {
      try{
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/postpage/${id}`);
        const data = await response.json();
        setPostinfo(data)
        const output = await authenticate();
        setAuthenticated(output);
      }
      catch(error){
          console.log("error in postpage",error);
      }
      finally{
        setLoading(false)
      }
    };
      fetchData();
  },[])

  return (
    <ThemeProvider>
  {loading ?(
    <div style={{
      height:"100%",
      width:"100%"
    }}>
      <Loader progress="60%" />
    </div>
    
  ) : (
    <div>
    <div className='postpage-main-div'>
    {authenticated && (
        <div>
                      <Button
                        id={"openPopoverBtn"}
                        onClick={() => {
                          setPopoverIsOpen(true);
                        }}
                      >
                        Delete Research
                      </Button>
                      <Popover
                        opener={"openPopoverBtn"}
                        headerText="Enter Delete to confirm"
                        horizontalAlign="Center"
                        placementType="Bottom"
                        open={popoverIsOpen}
                        onAfterClose={() => {
                          setPopoverIsOpen(false);
                        }}
                      >
                        <Label>
                          <input
                            type="text"
                            onChange={(e) => setInputvalue(e.target.value)}
                            value={inputvalue}
                            className="delete-member-input"
                            style={{
                              marginRight:"5px",
                              width:"90px",
                              border:
                                inputvalue === "Delete"
                                  ? "2px solid green"
                                  : "2px solid red",
                              outline: "none",
                              margin: "10px 0px",
                            }}
                            onMouseOver={() => setInputvalue("")}
                          />
                          <Button
                            style={{
                              color: "blue",
                              width: "80px",
                              height:"34px"
                            }}
                            onClick={(e) => deletefunc(id)}
                          >
                            Submit
                          </Button>
                        </Label>
                      </Popover>
                      <Link to={`/private/updatepost/${id}`}>
                    <Button>
                          Edit
                    </Button>
                    </Link>
                    </div>
      )}
        <div className='postpage-content-div'>
            <h1>{postinfo?.title}</h1>
            <p>{postinfo?.blogwriter}<span>{new Date(postinfo?.creatingdate).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}</span> </p>
            
            <p className='summary-text'>{postinfo?.summary}</p>
            <img src={postinfo.coverimg} alt="" />
            <div className='content-text' dangerouslySetInnerHTML={{__html:postinfo.content}}/>
        </div>
    </div>
    
</div>
  )}
  </ThemeProvider>
   
  )
}

export default Postpage