
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Blog from "../components/Blog.js";
import { Button } from "@ui5/webcomponents-react";
import authenticate from "../components/Authfunction.js";
import { IllustratedMessage } from '@ui5/webcomponents-react';
import "@ui5/webcomponents-icons/dist/newspaper.js";
import { ThemeProvider,Loader } from '@ui5/webcomponents-react';
const News = () => {
  const [posts, setPost] = useState([]);
  const [authenticated, setAuthenticated] = useState(false);
  const [loading,setLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      const output = await authenticate();
      setAuthenticated(output);
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/post`);
        const post = await response.json();
        setLoading(false)
        setPost(post);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <ThemeProvider>
  {loading ?(
    <div style={{
      height:"100%",
      width:"100%"
    }}>
      <Loader progress="60%" />
    </div>
    
  ) : (
    <div>
    <div className="courses-div">
      {authenticated &&(
        <Link to="/private/createpost">
          <Button icon="newspaper">
            Add News
          </Button>
        </Link>
      )}
      {
      posts.length > 0 ?(posts.map((post) => <Blog key={post.id} {...post} />))
      :(
        <IllustratedMessage
        subtitle={{}}
        subtitleText="This will be updated soon sorry for inconvenience"
        titleText="No news"
      />
      )
    }
    </div>
  </div>
  )}
  </ThemeProvider>
   
  );
};

export default News;
