import React from "react";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { Button } from "@ui5/webcomponents-react";
import { Grid } from "@ui5/webcomponents-react";
import { Card, List, StandardListItem } from "@ui5/webcomponents-react";
import authenticate from "../components/Authfunction.js";
import { IllustratedMessage } from '@ui5/webcomponents-react';
import { Popover, Label } from "@ui5/webcomponents-react";
import { ThemeProvider,Loader } from '@ui5/webcomponents-react';
import axios from "axios";
const Pidetails = () => {
    const [authenticated, setAuthenticated] = useState(false);
    const [members, setMembers] = useState([]);
    const [emptyarray,setEmptyarray] = useState(false);
    const [popoverIsOpen, setPopoverIsOpen] = useState([]);
    const [inputvalue, setInputvalue] = useState("");
    const [loading,setLoading] = useState(true);
    const deletefunc = async (_id) => {
      if (inputvalue === "Delete") {
          // console.log("Deleted");
        await axios
          .delete(
            `${process.env.REACT_APP_BACKEND_URL}/private/deletemember/${_id}`
          )
          .then((res) => {
            console.log(res);
            window.location.reload();
          })
          .catch((err) => {
            console.log(err);
          });
      }
    };
    useEffect(() => {
      const fetchData = async () => {
        try{
          const response =await fetch(`${process.env.REACT_APP_BACKEND_URL}/addmember/pidetails`)
          const member = await response.json();
          if (Array.isArray(member) && member.length === 0) {
            console.log("The response is empty");
            setEmptyarray(true)
            setLoading(false)
          } else {
            setMembers(member);
            setLoading(false)
            setPopoverIsOpen(new Array(member.length).fill(false));
          }
          const output = await authenticate();
          setAuthenticated(output);
        }
        catch(error){
          setEmptyarray(true)
          console.error("Error in pi details page:", error);
        }
        finally{
          setLoading(false);
        }
      };
    
      fetchData();
    }, []);
    const togglePopover = (index) => {
      const newPopoverIsOpen = [...popoverIsOpen];
      newPopoverIsOpen[index] = !newPopoverIsOpen[index];
      setPopoverIsOpen(newPopoverIsOpen);
    };
    return (
      <ThemeProvider>
      {loading ?(
        <div style={{
          height:"100%",
          width:"100%"
        }}>
          <Loader progress="60%" />
        </div>
        
      ) : (
        <div className="groupmember-main-div">
        <div className="gm-top-content-div">
        
          {authenticated && (
            <Link to="/private/addpi">
              <Button icon="employee" onClick={function _a() {}}>
                Add Members
              </Button>
            </Link>
          )}
        </div>
        {!emptyarray?(<div>
          <div className="grid-div">
            <Grid className="member-grid" hSpacing="7rem">
            
              {members.length > 0 &&
                members.map((member,index) => (
                  <React.Fragment key=".0">
                    <div
                      className="member-card"
                      style={{
                        backgroundColor: "white",
                        borderRadius: "20px",
                        boxShadow: "0 0 10px 2px rgba(0,0,0,0.2)",
                      }}
                    >
                      {authenticated&&(
                      <div>
                      <Button
                        id={`openPopoverBtn${index}`}
                        onClick={() => togglePopover(index)}
                      >
                        Delete member
                      </Button>
                      <Popover
                        opener={`openPopoverBtn${index}`}
                        // hideArrow
                        headerText="Enter Delete to confirm"
                        horizontalAlign="Center"
                        placementType="Bottom"
                        open={popoverIsOpen[index]}
                        onAfterClose={() => togglePopover(index)}
                      >
                        <Label>
                          <input
                            type="text"
                            onChange={(e) => setInputvalue(e.target.value)}
                            value={inputvalue}
                            className="delete-member-input"
                            style={{
                              marginRight: "5px",
                              width: "90px",
                              border:
                                inputvalue === "Delete"
                                  ? "2px solid green"
                                  : "2px solid red",
                              outline: "none",
                              margin: "10px 0px",
                            }}
                            onMouseOver={() => setInputvalue("")}
                          />
                          <Button
                            style={{
                              color: "blue",
                              width: "80px",
                              height: "34px",
                            }}
                            onClick={() => deletefunc(member._id, index)}
                          >
                            Submit
                          </Button>
                        </Label>
                      </Popover>
                      <Link to={`/private/updatepi/${member._id}`}>
                    <Button >
                      Edit
                    </Button>
                    </Link>
                    </div>
                      )}
                      <a href={`/memberpage/${member._id}`} style={{width:"100%",
                      height:"100% ",textDecoration:"none",
                      }}>
                      <div className="member-details">
                        <div className="member-img">
                          <img
                            src={member.coverimg}
                            alt="member img"
                          />
                        </div>
                       
                        <Card
                          style={{
                            width: "100%",
                          }}
                        >
                          <List>
                            <StandardListItem description={member.name}>
                            Name
                            </StandardListItem>
                            <StandardListItem description={member.email}>
                              Email
                            </StandardListItem>
                            <StandardListItem
                              description={member.currentposition}
                            >
                              Position
                            </StandardListItem>
                            <StandardListItem
                        description={member.expertise}
                      >
                        Expertise
                      </StandardListItem>
                            <a target="_blank" rel="noopener noreferrer" href={member.cvlink}>
                              <StandardListItem description="Link">
                                CV/Resume
                              </StandardListItem>
                            </a>
                          </List>
                        </Card>
                       
                      </div>
                      </a>
                    </div>
                    
                  </React.Fragment>
                ))}
            </Grid>
          </div>
        </div>):(
          <IllustratedMessage
          subtitle={{}}
          subtitleText="This will be updated soon sorry for inconvenience"
          titleText="No Principal Investigator"
        />
        )}
      </div>
      )}
      </ThemeProvider>
      
    );
}

export default Pidetails
