import React from 'react'
import { TabContainer,Tab } from '@ui5/webcomponents-react'; 
import Application from './Application';
import "@ui5/webcomponents-icons/dist/person-placeholder.js";
const Joinus = () => {
  return (
    <TabContainer onTabSelect={function _a(){}}>
    <Tab
      icon="person-placeholder"
      selected
      text="Phd Position"
    >
      <Application jobRole='Phd Position'/>
    </Tab>
    <Tab
      icon="person-placeholder"
      text="Msc trainee"
    >
      <Application jobRole='Msc trainee'/>
    </Tab>
    <Tab
      icon="person-placeholder"
      text="Research Analyst"
    >
      <Application jobRole='Research Analyst'/>
    </Tab>
  </TabContainer>
  )
}

export default Joinus