import React from "react";
import ReactQuill from "react-quill";
import axios from "axios";
import { useState } from "react";
import "react-quill/dist/quill.snow.css";
import { useNavigate } from "react-router-dom";
import { Button, ThemeProvider, Loader } from "@ui5/webcomponents-react";
import { DatePicker } from '@ui5/webcomponents-react';
import { FileUploader} from "@ui5/webcomponents-react";
import Alert from "./Alert";
const CreateResearchArea = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [title, setTitle] = useState("");
  const [summary, setSummary] = useState("");
  const [content, setContent] = useState("");
  const [creatingdate,setCreatingdata] = useState("");
  const [filevalue, setFilevalue] = useState("");
  const [loading, setLoading] = useState(false);
  const [alert,setAlert] = useState(null);
  const showAlert = (message,type)=>{
    setAlert({
       mes:message,
       type:type,
    })
   setTimeout(() => {
           setAlert(null);
          //  window.location.reload();
   }, 1500);
}
  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
      ["clean"],
    ],
  };
  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
  ];
  
  const sendpost = async (e) => {
    e.preventDefault();
    if((!username) || (!title) || (!summary) || (!content) || (!filevalue) || (!creatingdate))
    {
      showAlert("Please fill all the fields","danger");
      return;
    }
    setLoading(true);
    const formdataimg = new FormData();
    const formdata = new FormData();
    formdataimg.append("file", filevalue[0]);
    formdataimg.append("upload_preset", "cdriimg");
    formdataimg.append("cloud_name", "drujdpuxv");
    
    try {
      const response = await axios.post(
        "https://api.cloudinary.com/v1_1/drujdpuxv/image/upload/",
        formdataimg
      );
    
      // Access the response data directly
      formdata.append("imglink",response.data.url);
      formdata.append("imgid",response.data.public_id);
    } catch (err) {
      console.error("Error uploading image:", err);
    }
    
    formdata.append("blogwriter", username);
    formdata.append("title", title);
    formdata.append("summary", summary);
    formdata.append("content", content);
    formdata.append("creatingdate",creatingdate)
    const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/createreseracharea`, formdata)
      .then((res) => {
        setLoading(false);
        navigate("/researcharea");
      })
      .catch((err) => console.log(err));
      
  };

  return (
    <ThemeProvider>
      {loading ? (
        <div
          style={{
            height: "100%",
            width: "100%",
          }}
        >
          <Loader progress="60%" />
        </div>
      ) : (
        <div className="createpost-content">
          <Alert alert={alert} />
          <form action="post">
            <input
              type="name"
              value={username}
              placeholder={"Blog Writer"}
              className="title-class"
              onChange={(e) => setUsername(e.target.value)}
            />
            <input
              type="title"
              value={title}
              placeholder={"title"}
              className="title-class"
              onChange={(e) => setTitle(e.target.value)}
            />
            <input
              type="summary"
              value={summary}
              placeholder={"Summary"}
              onChange={(e) => setSummary(e.target.value)}
            />
            <DatePicker
            onChange={(e) => setCreatingdata(e.target.value)}
            onInput={function _a(){}}
            primaryCalendarType="Gregorian"
            value={creatingdate}
            placeholder="Ex Sep 14, 2015"
            required
          />
          <br />
            <FileUploader onChange={(e) => setFilevalue(e.target.files)} accept="image/*" required
            >
              <Button
              style={{
                color: "blue",
              }}
              >Your Profile Image</Button>
            </FileUploader>
            <ReactQuill
              value={content}
              modules={modules}
              formats={formats}
              onChange={(newcontent) => setContent(newcontent)}
            />
            <Button
              onClick={sendpost}
              style={{
                color: "blue",
                width: "100%",
                marginTop: "5px",
              }}
            >
              Submit Post
            </Button>
          </form>
        </div>
      )}
    </ThemeProvider>
  );
};

export default CreateResearchArea;
