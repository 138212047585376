import React, { useState,useEffect } from 'react'
import {Link} from 'react-router-dom'
import authenticate from '../components/Authfunction'
import { Button } from "@ui5/webcomponents-react";
import { IllustratedMessage } from '@ui5/webcomponents-react';
import "@ui5/webcomponents-icons/dist/add-folder.js";
import { NotificationListItem ,List,NotificationAction} from '@ui5/webcomponents-react';
import axios from "axios";
import { ThemeProvider,Loader } from '@ui5/webcomponents-react';

const Researchpublications  = () => {
  const [authenticated, setAuthenticated] = useState(false);
  const [publications,setPublications] = useState("")
  const [loading,setLoading] = useState(true);
  const deletefunc = async (_id) => {
    await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/deletepublication/${_id}`)
        .then((res) => {
          setLoading(false)
          console.log(res);
          window.location.reload();
        })
        .catch((err) => {
          console.log(err);
        });
  };
  useEffect(() => {
    const fetchData = async () => {
      
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/publication`)
        const publication = await response.json()
        setPublications(publication);
        setLoading(false)
        const output = await authenticate();
        setAuthenticated(output);
      
      } catch (error) {
        console.error("Error in Researchpublications page:", error);
      }
    };
  
    fetchData();
  }, []);

  return (
    <ThemeProvider>
  {loading ?(
    <div style={{
      height:"100%",
      width:"100%"
    }}>
      <Loader progress="60%" />
    </div>
    
  ) : (
    <div style={{
      display:"flex",
      flexDirection:"column",
      alignItems:"center",
      marginTop:"5px"
    }}>
      {authenticated &&(
      <Link to="/private/addpublication">
        <Button icon="add-folder">
          Add Publication
        </Button>
      </Link>
    )}
  {publications.length > 0 ? (
  <List>
  {publications.map((publication) => (
    <NotificationListItem
    actions={authenticated&&(<NotificationAction icon="message-error" text="Delete" onClick={(e)=>deletefunc(publication._id)}/>)}
    footnotes={<Link target="_blank" rel="noopener noreferrer" to={publication.publicationlink}>Link</Link>}
    onClose={function _a(){}}
    priority="None"
    titleText={publication.publication}
  >
    
  </NotificationListItem>
  ))}
</List>
) : (
  <IllustratedMessage
    subtitle={{}}
    subtitleText="This will be updated soon. Sorry for the inconvenience."
    titleText="No publications"
  />
)}
    </div>
  )}
  </ThemeProvider>
    
  )
}

export default Researchpublications 