import React, { useEffect, useState } from 'react'
import axios from 'axios';
import "@ui5/webcomponents-icons/dist/customer.js";
import { NotificationListGroupItem ,Avatar,NotificationAction,NotificationListItem} from '@ui5/webcomponents-react';
const Mailpage =  () => {
    const [readmails,setReadmails] = useState([]);
    const [unseenmails,setUnseenmails] = useState([]);
    useEffect(()=>{
        const fetchData = async ()=>{
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/contactus`);
                const mail = await response.json();
                const unseen = [];
                const read = [];
                
                mail.forEach(mail => {
                  if (mail.flag === false) {
                    unseen.push(mail);
                  } else {
                    read.push(mail);
                  }
                });
        
              setUnseenmails(unseen);
              setReadmails(read);
                // console.log(mail)
              } catch (error) {
                console.error("Error:", error);
              }
        }
        fetchData();
    },[])
    // useEffect(() => {
    //   console.log('Unseen mails:', unseenmails);
    //   console.log('Read mails:', readmails);
    // }, [unseenmails, readmails]);
    const deletemail = async (_id)=>{
        await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/deletemail/${_id}`)
        .then(res=>{
            console.log(res)
            window.location.reload();
        }).catch(err=>{console.log(err)})
    }
    const acceptemail = async (_id) =>{
      try {
        const response = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/contactus`, { id: _id});
        window.location.reload();
        console.log(response.data);
      } catch (error) {
        console.error(error);
      }
    }
  return (
    <>
    <NotificationListGroupItem
  onClose={function _a(){}}
  onToggle={function _a(){}}
  titleText="Unread Mails"
  showCounter
>
{unseenmails.length > 0 &&(unseenmails.map((mail) =>
<NotificationListItem 
    actions={<><NotificationAction icon="accept" text="Mark as read" onClick={(e)=>acceptemail(mail._id)}/><NotificationAction icon="message-error" text="Delete" onClick={(e)=>deletemail(mail._id)}/></>}
    avatar={<Avatar icon='customer' size="XS"></Avatar>}
    footnotes={<><span>{mail.name}</span><span>{new Date(mail.createdAt).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}</span> <span>{mail.email}</span></>}
    priority="High"
    
    titleText={`${mail.title}`}
    key = {mail.id}
  >
  </NotificationListItem>
))}
</NotificationListGroupItem>



<NotificationListGroupItem
  onClose={function _a(){}}
  onToggle={function _a(){}}
  titleText="Read Mails"
  showCounter
>
{readmails.length > 0 &&(readmails.map((mail) =>
<NotificationListItem 
    actions={<NotificationAction icon="message-error" text="Delete" onClick={(e)=>deletemail(mail._id)}/>}
    avatar={<Avatar icon='customer' size="XS"></Avatar>}
    footnotes={<><span>{mail.name}</span><span>{new Date(mail.createdAt).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}</span> <span>{mail.email}</span></>}
    priority="Medium"
    
    titleText={`${mail.title}`}
    key = {mail.id}
  >
  </NotificationListItem>


))}
</NotificationListGroupItem>
</>
  )
}

export default Mailpage