import { useState , useRef} from "react";
import { useNavigate } from "react-router-dom";
import { Form ,FormItem,FormGroup,Input,TextArea,Label} from '@ui5/webcomponents-react';
import { Button} from "@ui5/webcomponents-react";
import axios from 'axios'
import Alert from '../components/Alert.js'
import { ThemeProvider,Loader } from '@ui5/webcomponents-react';
import "@ui5/webcomponents/dist/features/InputElementsFormSupport.js"

export default function Contactus() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [title,setTitle] = useState("");
  const navigate = useNavigate();
  const [alert,setAlert] = useState(null);
  const [loading,setLoading] = useState(false);
  const formRef = useRef();

    const showAlert = (message,type)=>{
         setAlert({
            mes:message,
            type:type,
         })
        setTimeout(() => {
                setAlert(null);
                window.location.reload();
        }, 1500);
    }
    const handleSubmit = async (e) => {
      e.preventDefault();
      const subject = encodeURIComponent(`${title}`);
      const body = encodeURIComponent(`Message:\n${message}`);
      window.location.href = `mailto:2105369@kiit.ac.in?subject=${subject}&body=${body}`;
      if((!name) || (!email) || (!title) || (!message)) {
        showAlert("Please fill all the fields","danger");
        return;
      }
      if (!email.includes("@")) {
        showAlert("Invalid email", "danger");
        return;
      }
      setLoading(true)
        await axios.post(`${process.env.REACT_APP_BACKEND_URL}/contactus`,{name,email,message,title})
    .then(res => {
      if(res.data.status === 'ok')
      {
        setLoading(false)
        showAlert("Mail sent successfully","success");
      }
      else
      {
        showAlert(`${res.data.error}`,"danger");
      }
        
    }
    )
    .catch(err => {console.log(err)
      showAlert(`${err.message}`,"danger");
    });
    };
    
  return (
    <ThemeProvider>
  {loading ?(
    <div style={{
      height:"100%",
      width:"100%"
    }}>
      <Loader progress="60%" />
    </div>
    
  ) : (
    <div style={{
      padding: "20px 20px",
      backgroundColor:"rgb(245,246,247)",
    }} >
      <Alert alert={alert}/>
      <Form
      ref={formRef}
      backgroundDesign="Transparent"
      columnsL={1}
      columnsM={1}
      columnsS={1}
      columnsXL={2}
      labelSpanL={4}
      labelSpanM={2}
      labelSpanS={12}
      labelSpanXL={4}
      style={{
          alignItems: 'center'
      }}
      titleText="Contact Us"
      >
  <FormItem label={<Label required>Name</Label>}>
    <Input name="user_name" required value={name} onChange={e=>setName(e.target.value)}/>
  </FormItem>
  <FormItem label={<Label required>Email</Label>}>
    <Input name="user_email" type="email" required value={email} onChange={e=>setEmail(e.target.value)}/>
  </FormItem>
  <FormItem label={<Label required>Title</Label>}>
    <Input name="user_title" required value={title} onChange={e=>setTitle(e.target.value)}/>
  </FormItem>
  <FormItem label={<Label style={{alignSelf: 'start', paddingTop: '0.25rem'}}required>Message</Label>}>
      <TextArea
        name="user_message"
        required
        placeholder="Tell somthing about yourself"
        rows={5}
        value={message}
        onChange={e=>setMessage(e.target.value)}
      />
    </FormItem>

</Form>
  <div style={{
      display:"flex",
      flexDirection:"column",
      alignItems:"center",
      height:"100px",
      width:"100%",
  }}>
 <Button  onClick={handleSubmit}
   style={{
     color: "blue",
     width:"90px",
   }}
   >Send</Button>
  </div>
      
      </div>
  )}
  </ThemeProvider>
    
  );
}